<template>
    <div class="d-flex fullheight">
      <div class="align-self-center limitar">
      <div class="tarjeta">
        <div class="row">
          <div class="col-sm-12 col-md-6 text-center">
            <img alt="Vue logo" src="../assets/logo.png" class="mt-3 mb-5 img-fluid">
          </div>
          <div class="col-sm-12 col-md-6 text-center">
            <form id="signup-form" class="h-100 d-flex" @submit.prevent="processForm">
              <div class="align-self-center w-100">
                <input name="usuario" v-model="usuario" placeholder="DNI" class="form-control mb-3" />
                <input name="contrasena" v-model="contrasena" placeholder="Número de tarjeta" type="password" class="form-control mb-3" />
                <input type="hidden" v-model="cliente" name="cliente" />
                <input type="hidden" v-model="tipo" name="tipo" />

                <div class="error" v-show="mostrar">DNI o número de tarjeta incorrectos</div>
                <button type="submit" name="ok" class="btn btn-danger"><i class="fa fa-card"></i> Mostrar Tarjeta</button>
              </div>
            </form>
          </div>
        </div>
        </div>

<div class="text-center mt-4"><button type="button" id="install_button" name="install_button" class="btn bg-light">Instalar esta App</button></div>
      </div>
  </div>


</template>

<script>
import axios from "axios";

export default {

 data() {
    return {
        mostrar: false,
        tipo: 'login',
        usuario: '',
        cliente: 13,
        contrasena: ''
    }
  },
  // our methods
  methods: {
    created: function() {
    },
    processForm: function() {

      const params = new URLSearchParams();
      params.append('tipo', this.tipo);
      params.append('usuario', this.usuario);
      params.append('cliente', this.cliente);
      params.append('contrasena', this.contrasena);

      axios.post('https://globalidenti.com/admin/_api/tarjeta.php', params)
     .then((res) => {

        if(res.data.status == 'ok'){
        this.$router.push({ name: 'Loged', params: res.data.details });
        } else {
          this.mostrar = true;
          setTimeout(() => this.mostrar = false, 2000)
        }
     })
     .catch(() => {
         this.mostrar = true;
     });
    }
  },
mounted () {



let deferredPrompt; // Allows to show the install prompt
const installButton = document.getElementById("install_button");

window.addEventListener("beforeinstallprompt", e => {
  console.log("beforeinstallprompt fired");
  // Prevent Chrome 76 and earlier from automatically showing a prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Show the install button
  installButton.hidden = false;
  installButton.addEventListener("click", installApp);
});

function installApp() {
  // Show the prompt
  deferredPrompt.prompt();
  installButton.disabled = true;

  // Wait for the user to respond to the prompt
  deferredPrompt.userChoice.then(choiceResult => {
    if (choiceResult.outcome === "accepted") {
      console.log("PWA setup accepted");
      installButton.hidden = true;
    } else {
      console.log("PWA setup rejected");
    }
    installButton.disabled = false;
    deferredPrompt = null;
  });
}

window.addEventListener("appinstalled", evt => {
  console.log("appinstalled fired", evt);
});

}
}


</script>

<style>
.fullheight {
  height: 100vh;
  justify-content: center;
}

.error {
    background: #fff5cf;
    color: #de0000;
    padding: 0.5em;
    margin-bottom: 1em;
    border-radius: 5px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
